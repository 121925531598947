<template>
    <div>

        <a-row type="flex" justify="center" align="top">
            <a-col :span="3" class="list-left">
                <a-tabs default-active-key="tab_active" v-model="type" :tab-position="tabPosition" type="card" @change="tabChange">
                    <a-tab-pane key="0" tab="入库记录"></a-tab-pane>
                    <a-tab-pane key="1" tab="出库记录"></a-tab-pane>
                    <a-tab-pane key="2" tab="报损记录"></a-tab-pane>
                </a-tabs>
            </a-col>
            <a-col :span="21" class="list-right">
                <!--入库记录-->
                <div class="rukulist" v-if="Number(type) === 0">
                    <a-form-model layout="inline">
                        <a-form-model-item >
                            <a-select v-model="formInline.group_id" style="width: 200px">
                                <a-select-option  :value="0">全部试剂</a-select-option>
                                <a-select-option  v-for="(item,key) in Group_list" :value="item.id">{{item.name}}</a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item >
                            <a-input placeholder="请输入试剂名称或简拼" v-model="formInline.keywords" ></a-input>
                        </a-form-model-item>

                        <a-form-model-item label="详细查询">
                            <!--:defaultValue="1"-->
                            <a-date-picker v-model="formInline.starttime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                            <span> - </span>
                            <a-date-picker v-model="formInline.endtime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                        </a-form-model-item>
                        <a-form-model-item label="">
                            <a-button type="danger" @click="Clear()">清空</a-button>
                        </a-form-model-item>
                        <a-form-model-item label="" style="float: right">
                            <a-button type="primary" @click="Shijichuruku_export()">导出筛选数据</a-button>
                        </a-form-model-item>
                    </a-form-model>
                    <table class="layui-table" lay-size="sm">
                        <tr>
                            <th>入库时间</th>
                            <th>入库数量</th>
                            <th>存放地点</th>
                            <th>入库单号</th>
                            <th>操作人</th>
                            <th>试剂组别</th>
                            <th>试剂名称</th>
                            <th>生产厂家</th>
                            <th>有效期</th>
                            <th>试剂规格</th>
                            <th>批准文号</th>
                            <th>生产批号</th>
                            <th>单价</th>
                            <th>总金额</th>
                        </tr>
                        <tr v-for="(item,key) in Get_reagentstock_list" :key="key">
                            <td>{{item.do_time}}</td>
                            <td>{{item.stock_num}}</td>
                            <td>{{item.storage_location}}</td>
                            <td>{{item.ordernum}}</td>
                            <td>{{item.danda_name}}</td>
                            <td>{{item.group_name}}</td>
                            <td>{{item.reagent_info_name}}</td>
                            <td>{{item.full_name}}</td>
                            <td>{{item.valid_time}}</td>
                            <td>{{item.spec}}</td>
                            <td>{{item.approval_number}}</td>
                            <td>{{item.batch}}</td>
                            <td>{{item.price}}</td>
                            <td>{{item.totalprice}}</td>
                        </tr>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>

                <!-- 出库记录 -->
                <div class="rukulist" v-if="Number(type) == 1">
                    <a-form-model layout="inline">
                        <a-form-model-item >
                            <a-select v-model="formInline.group_id" style="width: 200px">
                                <a-select-option  :value="0">全部试剂</a-select-option>
                                <a-select-option  v-for="(item,key) in Group_list" :value="item.id">{{item.name}}</a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item >
                            <a-input placeholder="请输入试剂名称或简拼" v-model="formInline.keywords" ></a-input>
                        </a-form-model-item>

                        <a-form-model-item label="详细查询">
                            <!--:defaultValue="1"-->
                            <a-date-picker v-model="formInline.starttime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                            <span> - </span>
                            <a-date-picker v-model="formInline.endtime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                        </a-form-model-item>

                        <a-form-model-item label="">
                            <a-button type="danger" @click="Clear()">清空</a-button>
                        </a-form-model-item>
                        <a-form-model-item label="" style="float: right">
                            <a-button type="primary" @click="Shijichuruku_export()">导出筛选数据</a-button>
                        </a-form-model-item>
                    </a-form-model>
                    <table  class="layui-table" lay-size="sm">
                        <tr>
                            <th>出库时间</th>
                            <th>出库数量</th>
                            <th>领取人</th>
                            <th>出库单号</th>
                            <th>操作人</th>
                            <th>试剂组别</th>
                            <th>试剂名称</th>
                            <th>生产厂家</th>
                            <th>有效期</th>
                            <th>试剂规格</th>
                            <th>批准文号</th>
                            <th>生产批号</th>
                            <th>单价</th>
                            <th>总金额</th>
                        </tr>
                        <tr v-for="(item,key) in Get_reagentstock_list" :key="key">
                            <td>{{item.do_time}}</td>
                            <td>{{item.stock_num}}</td>
                            <td>{{item.user_name}}</td>
                            <td>{{item.ordernum}}</td>
                            <td>{{item.danda_name}}</td>
                            <td>{{item.group_name}}</td>
                            <td>{{item.reagent_info_name}}</td>
                            <td>{{item.full_name}}</td>
                            <td>{{item.valid_time}}</td>
                            <td>{{item.spec}}</td>
                            <td>{{item.approval_number}}</td>
                            <td>{{item.batch}}</td>
                            <td>{{item.price}}</td>
                            <td>{{item.totalprice}}</td>
                        </tr>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>

                <!-- 报损记录 -->
                <div class="rukulist" v-if="Number(type) == 2">

                    <a-form-model layout="inline">
                        <a-form-model-item >
                            <a-select v-model="formInline.group_id" style="width: 200px">
                                <a-select-option  :value="0">全部试剂</a-select-option>
                                <a-select-option  v-for="(item,key) in Group_list" :value="item.id">{{item.name}}</a-select-option>
                            </a-select>
                        </a-form-model-item>

                        <a-form-model-item >
                            <a-input placeholder="请输入试剂名称或简拼" v-model="formInline.keywords" ></a-input>
                        </a-form-model-item>

                        <a-form-model-item label="详细查询">
                            <!--:defaultValue="1"-->
                            <a-date-picker v-model="formInline.starttime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                            <span> - </span>
                            <a-date-picker v-model="formInline.endtime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
                        </a-form-model-item>

                        <a-form-model-item label="">
                            <a-button type="danger" @click="Clear()">清空</a-button>
                        </a-form-model-item>
                        <a-form-model-item label="" style="float: right">
                            <a-button type="primary" @click="Shijichuruku_export()">导出筛选数据</a-button>
                        </a-form-model-item>
                    </a-form-model>
                    <table  class="layui-table" lay-size="sm">
                        <tr>
                            <th>报损时间</th>
                            <th>报损数量</th>
                            <th>报损单号</th>
                            <th>操作者</th>
                            <th>试剂组别</th>
                            <th>试剂名称</th>
                            <th>生产厂家</th>
                            <th>有效期</th>
                            <th>试剂规格</th>
                            <th>批准文号</th>
                            <th>生产批号</th>
                            <th>存放地点</th>
                            <th>单价</th>
                            <th>总金额</th>
                        </tr>
                        <tr v-for="(item,key) in Get_reagentstock_list" :key="key">
                            <td>{{item.do_time}}</td>
                            <td>{{item.stock_num}}</td>
                            <td>{{item.ordernum}}</td>
                            <td>{{item.danda_name}}</td>
                            <td>{{item.group_name}}</td>
                            <td>{{item.reagent_info_name}}</td>
                            <td>{{item.full_name}}</td>
                            <td>{{item.valid_time}}</td>
                            <td>{{item.spec}}</td>
                            <td>{{item.approval_number}}</td>
                            <td>{{item.batch}}</td>
                            <td>{{item.storage_location}}</td>
                            <td>{{item.price}}</td>
                            <td>{{item.totalprice}}</td>
                        </tr>
                    </table>
                    <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
                </div>


            </a-col>
        </a-row>

    </div>
</template>

<script>
    // import moment from 'moment';
    export default {
        components: {},
        data() {
            return {
                Group_list:[],
                type: '0',
                page: 1,
                count: 0,
                pagesize: 0,
                Get_reagentstock_list: [],
                tab_active: '0',
                tabPosition: 'left',
                formInline: {
                    group_id:0,
                    searchtype: '',
                    starttime: '',
                    endtime: '',
                    keywords:''
                },
                page_show: false,
                startValue: null,
            };
        },
        watch: {
            formInline: {
                handler() {
                    if (this.page_show) this.searchClick()
                },
                deep: true
            },
            $route: {
                handler() {
                    this.mountedDo()
                },
                deep: true
            }
        },

        mounted() {
            this.mountedDo()
            this.Sampletypelist()// w获取试剂组别列表
        },


        methods: {

            mountedDo() {
                this.page = Number(this.$route.query.page) || 1;
                this.formInline.searchtype = this.$route.query.searchtype || '0';
                this.type = this.$route.query.type || '0';
                this.formInline.starttime = this.$route.query.starttime || '';
                this.formInline.endtime = this.$route.query.endtime || '';
                this.formInline.group_id = Number(this.$route.query.group_id) || 0;
                this.formInline.keywords = this.$route.query.keywords || '';
                this.Get_reagentstock()
                setTimeout(() => {
                    this.page_show = true
                }, 1)
            },

            searchClick() {
                console.log(this.formInline.keywords)
                this.$router.push({
                    query: {
                        group_id: this.formInline.group_id,
                        keywords: this.formInline.keywords,
                        type: this.type,
                        starttime: this.formInline.starttime,
                        endtime: this.formInline.endtime,
                        page: 1,

                    },

                })
            },

            Clear() {
                this.$router.push({
                    query: {
                        group_id:0,
                        searchtype: '',
                        starttime: '',
                        endtime: '',
                        keywords:'',
                        page: 1,
                    }
                })
            },

            //获取试剂组别列表
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 4,
                    },

                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Group_list = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //试剂出入库导出记录
            Shijichuruku_export() {
                console.log(JSON.stringify({
                    group_id: this.formInline.group_id,
                    keywords: this.formInline.keywords,
                    type: this.type,
                    starttime: this.formInline.starttime,
                    endtime: this.formInline.endtime,
                }))
                this.$sa0.down({
                    url: this.$api('Shijichuruku_export'),
                    file_name: 'down1.xlsx',
                    data: {
                        group_id: this.formInline.group_id,
                        keywords: this.formInline.keywords,
                        type: this.type,
                        starttime: this.formInline.starttime,
                        endtime: this.formInline.endtime,
                    },
                },)
            },
            // w入库/出库/报损记录
            Get_reagentstock() {
                this.$sa0.post({
                    url: this.$api('Get_reagentstock'),
                    data: {
                        type: this.type,
                        page: this.page,
                        searchtype: this.formInline.searchtype,
                        starttime: this.formInline.starttime,
                        endtime: this.formInline.endtime,
                        group_id: this.formInline.group_id,
                        keywords: this.formInline.keywords,
                    },

                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_reagentstock_list = response.data.result.list;
                            //console.log(JSON.stringify(this.Get_reagentstock_list))
                            this.count = response.data.result.count;//分页
                            this.pagesize = response.data.result.pagesize;//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                        type: this.type
                    }
                })
            },

            // 左侧tab切换
            tabChange(key) {
                this.$router.push({
                    query: {
                        page: 1,
                        type: key
                    }
                })
            },

            callback(val) {
                console.log(val);
            },


        },
    };
</script>


<style>

    .list-left {
        background: #f9f9f9;
        min-height: 850px;
        border: 1px solid #eeeeee;
        border-right: none;
        height: 100%;
        padding: 20px 0 0 10px
    }

    .list-right {
        padding: 0 25px;
    }

    .ant-tabs .ant-tabs-left-bar {
        float: right !important;
        margin-right: -1px;
        margin-bottom: 0;
        border-right: 1px solid #e8e8e8;
        width: 100% !important;
    }

    .ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
        margin-right: -1px;
        padding-right: 40px !important;
        font-weight: bold;
    }

</style>
